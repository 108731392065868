import { createWebHistory, createRouter } from 'vue-router';
import authRoutes from './authRoutes';
import registerRoutes from './RegisterRoutes';
import store from '@/vuex/store';

const routes = [
  {
    name: 'Registro',
    path: '/',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withRegisterLayout.vue'),
    children: [...registerRoutes],
    meta: { auth: false, title: 'Registro Centro Emprendemos Monterrey de la Secretaría de Desarrollo Económico - Gobierno de Monterrey' },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true, title: 'Acceso Centro Emprendemos Monterrey de la Secretaría de Desarrollo Económico - Gobierno de Monterrey'},
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  
  if (to.meta.auth && store.state.auth.login) {
    next({ to: '/' });
  } else if (!to.meta.auth && !store.state.auth.login) {
    next({ name: 'login' });
  } else {    
    next();
  }

  window.scrollTo(0,0);
});


export default router;