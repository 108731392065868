export default [
  {
    path: '',
    name: 'registro',
    components: {
      default: () => import(/* webpackChunkName: "wizard" */ '@/view/registro/Registro.vue')
	},
  },
  {
    path: 'encuesta',
    name: 'encuesta',
    components: {
      default: () => import(/* webpackChunkName: "wizard" */ '@/view/encuesta/Encuesta.vue')
	},
  }
];